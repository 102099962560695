export default () => ({
    showAddMemberModal: false,
    formData: {
        fullName: '',
        email: '',
    },
    processing: false,
    error: null,

    openModal() {
        this.showAddMemberModal = true;
        this.error = null;
        this.formData.fullName = '';
        this.formData.email = '';
    },

    closeModal() {
        this.showAddMemberModal = false;
        this.error = null;
    },

    async submitForm() {
        this.processing = true;
        this.error = null;

        try {
            const response = await fetch('/actions/site-module/users/add-team-member', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...this.formData,
                    orgId: document.querySelector('input[name="orgId"]').value,
                    CRAFT_CSRF_TOKEN: document.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value,
                }),
            });

            const data = await response.json();

            if (data.success) {
                // Close modal and refresh the page to show the new member
                this.closeModal();
                window.location.reload();
            } else {
                this.error = data.error || 'Failed to add team member';
                this.processing = false;
            }
        } catch (error) {
            console.error('Error:', error);
            this.error = 'An unexpected error occurred';
            this.processing = false;
        }
    }
});
